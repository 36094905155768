// 设备管理
<template>

    <div class='deviceManage baseBg'>
        <el-container :style="{'padding':0,'overflow-y':'auto','height':commonJs.getTableHeight({ pageOtherHeight: 80 })+'px'}">
            <el-aside>
                <div class="treeBox">
                    <el-tree :data="customerTree"
                             ref="tree"
                             :props="defaultProps"
                             :expand-on-click-node="false"
                             :current-node-key="treeNodeClickItem.id"
                             :highlight-current="true"
                             node-key="id"
                             :default-expand-all="false"
                             @node-click="handleNodeClick"></el-tree>
                </div>
            </el-aside>
            <el-main>
                <div class="searchBar">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :inline="true"
                             size="normal">
                        <el-form-item label="供应商">
                            <customer-or-provider-select-tree ref="selectTree"
                                                              type="PROVIDER"
                                                              :shouldClean="true"
                                                              @handClear="clearProOrCust"
                                                              @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                        </el-form-item>
                        <el-form-item label="设备类型">
                            <el-select v-model="searchForm.empTypeId"
                                       placeholder="请选择设备类型"
                                       clearable>
                                <el-option v-for="item in empTypeIdOpts"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 魏林说做不了 -->
                        <!-- <el-form-item label="物料清单">
                            <SelectBomByDeviceType v-model="searchForm.matterId"
                                                   placeholder="请选择物料清单"
                                                   :disabled="searchForm.empTypeId ? false : true"
                                                   :empTypeId="searchForm.empTypeId"></SelectBomByDeviceType>
                        </el-form-item> -->
                        <el-form-item>
                            <el-button type="primary"
                                       @click="getAllList()">搜索</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type=""
                                       @click="resetSearchForm">清除</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="listBox">
                    <div class="tableBox"
                         v-loading="loading">
                        <el-table :data="tableData.data"
                                  border
                                  ref="tableBox"
                                  :height="tableHeight"
                                  @row-click="rowClick"
                                  stripe>
                            <el-table-column v-for="col in tableData.columns"
                                             :prop="col.id"
                                             :key="col.id"
                                             :label="col.label"
                                             align="center"
                                             :min-width="col.width">
                                <template slot-scope='scope'>
                                    <el-image @click="handleImgClick(`${PATH}/file/view/?file=${scope.row[col.id]}&access-token=${token}`)"
                                              style="width: 100px; height: 100px"
                                              fit="scale-down"
                                              :src="`${PATH}/file/view/?file=${scope.row[col.id]}&access-token=${token}`"
                                              v-if="col.id === 'qrCodePath' && scope.row[col.id]" />
                                    <span v-else>{{scope.row[col.id]}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 分页 -->
                        <!-- <Pagination :page="searchForm.page"
                                    :limit="searchForm.size"
                                    :total="pageTotal"
                                    @pagination="paginationChange" /> -->
                    </div>
                    <div class="tableBox"
                         v-loading="loading2">
                        <el-table :data="tableData2.data"
                                  border
                                  ref="tableBox2"
                                  :height="tableHeight"
                                  stripe>
                            <el-table-column v-for="col in tableData2.columns"
                                             :prop="col.id"
                                             :key="col.id"
                                             :label="col.label"
                                             align="center"
                                             :min-width="col.width">
                                <template slot-scope='scope'>
                                    <el-image @click="handleImgClick(`${PATH}/file/view/?file=${scope.row[col.id]}&access-token=${token}`)"
                                              style="width: 100px; height: 100px"
                                              fit="scale-down"
                                              :src="`${PATH}/file/view/?file=${scope.row[col.id]}&access-token=${token}`"
                                              v-if="col.id === 'qrCodePath' && scope.row[col.id]" />
                                    <span v-else>{{scope.row[col.id]}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id"
                                             label="操作"
                                             align="center"
                                             min-width="100">
                                <template slot-scope="scope">
                                    <el-link type="primary"
                                             @click="details(scope.row)">查看</el-link>
                                    <el-link type="danger"
                                             @click="exportQR(scope.row)">导出</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 分页 -->
                        <Pagination :page="searchForm2.page"
                                    :limit="searchForm2.size"
                                    :total="pageTotal2"
                                    @pagination="paginationChange2" />
                    </div>
                </div>
            </el-main>
        </el-container>

        <!-- 导出二维码弹框 -->
        <exportQRcode v-if="exportQRcodeVisible"
                      :row="row"
                      @close="closeexportQRcode"></exportQRcode>

        <!-- 查看大图 -->
        <el-image-viewer v-if="showViewer"
                         :on-close="closeViewer"
                         :url-list="viewerImgList" />
    </div>

</template>

<script>
import customerOrProviderSelectTree from "@/components/selectTree/customerOrProviderSelectTree.vue";
import { Loading } from "element-ui";
import exportQRcode from "../stockManage/components/pop_exportQRcode";
import Pagination from "@/components/Pagination"; // 分页
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "deviceManage",

    props: [],

    components: {
        Pagination,
        ElImageViewer,
        exportQRcode,
        "customer-or-provider-select-tree": customerOrProviderSelectTree,
    },

    data() {
        return {
            PATH: window.configPath.baseUrl,
            token: this.commonJs.getCookie("ERPToken"),
            showViewer: false, // 缩略图展示框是否显示
            viewerImgList: [], // 预览图片列表
            exportQRcodeVisible: false, // 导出二维码弹窗显示
            // 筛选表单
            searchForm: {
                empTypeId: "", // 设备类型
                providerId: "", // 供应商
                systemId: "", // 客户id 树形图选中的
                qrCode: "", // 设备编号
                page: 1,
                size: 1,
            },
            searchForm2: {
                page: 1,
                size: 20,
                status: "OUT_ACCEPTANCE",
            },
            pageTotal: 0, // 分页总数
            pageTotal2: 0, // 分页总数
            supplierOpts: [{ label: "全部", value: "" }],
            empTypeIdOpts: [{ label: "全部", value: "" }], // 设备类型
            time: [],
            tableData: {
                columns: [
                    {
                        id: "empType",
                        label: "设备类型",
                        width: "100",
                    },
                    {
                        id: "provider",
                        label: "供应商",
                        width: "100",
                    },
                    {
                        id: "typeModel",
                        label: "规格型号",
                        width: "100",
                    },
                    {
                        id: "arrangeNum",
                        label: "数量",
                        width: "80",
                    },
                ],
                data: [],
            },
            tableData2: {
                columns: [
                    {
                        id: "qrCode",
                        label: "设备编号",
                        width: "180",
                    },
                    {
                        id: "qrCodePath",
                        label: "设备二维码",
                        width: "180",
                    },
                ],
                data: [],
            },
            customerTree: [],
            treeNodeClickItem: {}, // 默认勾选的树
            defaultProps: {
                children: "children",
                label: "label",
            },
            loading: false, // 左列表加载中
            loading2: false, // 右侧加载中
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 获取客户树
        this.getCustomerTree();
        // 获取设备类型下拉数据
        this.getDeviceTypeOpts();
    },

    mounted() {
        // 获取客户树
        this.getCustomerTree();
        // 获取设备类型下拉数据
        this.getDeviceTypeOpts();
    },

    methods: {
        // 导出
        exportQR(item) {
            this.row = item;
            this.exportQRcodeVisible = true;
        },
        // 关闭导出二维码弹框
        closeexportQRcode() {
            this.row = "";
            this.exportQRcodeVisible = false;
        },
        // 点击缩略图的方法
        handleImgClick(url) {
            this.showViewer = true;
            this.viewerImgList = [url];
        },

        // 关闭预览图片
        closeViewer() {
            this.showViewer = false;
            this.viewerImgList = [];
        },
        // 获取设备类型下拉数据
        getDeviceTypeOpts() {
            commonAPI.getDeviceTypeOpts().then((res) => {
                let data = res.content;
                let opt = data.map((val) => {
                    return { label: val["label"], value: val["code"] };
                });
                this.empTypeIdOpts = opt;
            });
        },
        // 供应商的选择
        selectTreeCustClick(node) {
            this.searchForm.providerId = node.id;
        },
        //清空客户供应商
        clearProOrCust() {
            this.searchForm.providerId = "";
        },
        // 左列表
        getAllList() {
            this.loading = true;
            let data = Object.assign({}, this.searchForm, {
                // page: page || this.searchForm.page,
                page: 1,
                size: 9999,
            });
            commonAPI
                .getDeviceManageAllList(data)
                .then((res) => {
                    this.loading = false;
                    this.tableData.data = res.content;
                    // this.total = res.content.total;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 设备管理模块列表
        getList() {
            this.loading2 = true;
            let data = Object.assign({}, this.searchForm2, {
                systemId: this.searchForm.systemId,
            });
            commonAPI
                .getDeviceManageList(data)
                .then((res) => {
                    this.loading2 = false;
                    this.tableData2.data = res.content.records;
                    this.pageTotal2 = res.content.total;
                })
                .catch(() => {
                    this.loading2 = false;
                });
        },
        // 点击左侧行
        rowClick(row) {
            this.tableData2.data = [];
            this.searchForm2.modelId = row.modelId;
            this.searchForm2.page = 1;
            if (
                row.haveCode === null ||
                (row.haveCode && row.haveCode === "YES")
            ) {
                this.getList();
            }
        },
        // 获取客户树
        getCustomerTree() {
            let loading = Loading.service({
                target: document.querySelector(".treeBox"),
            });
            commonAPI
                .getCustomerSelectTree({ type: "CUSTOMER" })
                .then((res) => {
                    loading.close();
                    this.customerTree = res.content;
                    this.treeNodeClickItem = {
                        id: res.content[0].id,
                    };
                    // 设置当前为选中状态
                    this.$nextTick(function () {
                        this.$refs["tree"].setCurrentKey(
                            this.treeNodeClickItem.id
                        );
                    });
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 改变分页
        paginationChange(e) {
            this.searchForm.size = e.limit;
            this.searchForm.page = e.page;
            this.getAllList();
        },
        // 改变分页
        paginationChange2(e) {
            this.searchForm2.size = e.limit;
            this.searchForm2.page = e.page;
            this.getList();
        },
        // 查看详情
        details(item) {
            this.$router.push({
                path: "/stockManage/deviceInoutRecord",
                query: { modelId: item.modelId, empId: item.id },
            });
        },
        // 点击tree
        handleNodeClick(data) {
            console.log(data);
            this.tableData2.data = []; // 清空右侧table
            this.treeNodeClickItem = { id: data.id };
        },

        //清空筛选条件
        resetSearchForm() {
            this.$refs.selectTree.cleanSelect(); //清空供应商
            this.searchForm.empTypeId = "";
            this.searchForm.providerId = "";
            this.searchForm.qrCode = "";
            this.getAllList();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 240 });
        },
    },

    watch: {
        "treeNodeClickItem.id": function () {
            this.searchForm.systemId = this.treeNodeClickItem.id;
            // 设备管理模块列表
            this.getAllList();
        },
    },
};
</script>

<style lang='scss' scoped>
.el-container {
    min-height: calc(100vh - 125px);
}
.el-main {
    padding-top: 0;
    padding-bottom: 0;
}
.el-aside {
    width: 300px !important;
}
.listBox {
    display: flex;
    .tableBox {
        flex: 1;
        width: 50%;
        margin-right: 20px;
    }
}
</style>
